<template>
    <div class="questions">
        <div class="card">
            <TabView v-model:activeIndex="activeTab">
                <TabPanel v-for="(question, index) in questions" :key="index" :header="index + 1"
                    :disabled="question.disabled || exam.can_move_between_questions == 0">
                    <div class="question">
                        <Button v-if="(exam.correction_status == 1 || exam.correction_status == 3) && question.answer_explanation != null" :label="$t('show_answer_result')" class="btn showAnswerResult PrimaryButton mb-3" icon="pi pi-external-link" @click="ShowExplainAnswer(index)" />
                        <Dialog v-model:visible="question.visible" :dismissableMask="true" maximizable modal :header="$t('show_answer_result')" :style="{ width: '80%' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" class="answer_explanation">
                            <img :src="explain_answer.answer_image" v-if="explain_answer.answer_image">
                            <Youtube :videoId="videoId" v-if="explain_answer.answer_video" />
                            <p class="m-0" v-if="explain_answer.answer_description">
                                {{explain_answer.answer_description}}
                            </p>
                        </Dialog>
                        <!-- <button type="button" class="btn showAnswerResult PrimaryButton">{{ $t("show_answer_result") }}</button> -->
                        <p class="question_title">{{ question.title }}</p>
                        <img v-if="question.image" :src="question.image" :alt="question.title">
                    </div>
                    <ul class="answer_questions">
                        <li v-for="(answer, item) in question.answers" :key="item">
                            <div class="form-check" :class="{
                                    success: (this.exam.correction_status === 1 || this.exam.correction_status === 4) && answer.is_correct === 1 && question.selectedAnswer === answer.id,
                                    wrong: (this.exam.correction_status === 1 || this.exam.correction_status === 4) && answer.is_correct === 0 && question.selectedAnswer === answer.id
                                }">
                                <label class="form-check-label" :for="`question${question.id}_answer${answer.id}`">
                                    <p class="answer_title">{{ answer.title }}</p>
                                    <img v-if="answer.image" :src="answer.image" :alt="answer.title">
                                </label>
                                <input class="form-check-input" type="radio" name="answerRadio" :value="answer.id"
                                    :id="`question${question.id}_answer${answer.id}`" v-model="question.selectedAnswer" @change="checkAnswerCorrect(answer.is_correct)">
                            </div>
                        </li>
                    </ul>
                    <button type="button" class="btn PrimaryButton" :disabled="this.exam.correction_status == 4 && correctAnswer == false"
                        @click="submitQuestion(question.id, question.selectedAnswer, index)">{{ this.questions.length - 1 == this.activeTab ? $t("end_exam") : $t("next") }}</button>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import 'primeicons/primeicons.css'
import Youtube from '../video/youtube.vue';

export default {
    props: {
        questions: Array,
        exam: Object,
    },
    data() {
        return {
            activeTab: 0, // Assuming the initial active tab is 0
            answerData: {},
            is_correct: false,
            correctAnswer: false,
            visible: false,
            explain_answer: {},
            videoId: ""
        }
    },
    components: {
        Youtube,
        TabView,
        TabPanel,
        Dialog,
        Button
    },
    methods: {
        ShowExplainAnswer(index) {
            const updatedQuestions = this.questions[index]
            this.explain_answer = this.questions[index].answer_explanation;

            if(this.questions[index].answer_explanation.answer_video) {
                // Extract video ID from URL
                const url = this.questions[index].answer_explanation.answer_video;
                const videoId = this.extractVideoId(url);
                this.videoId = videoId;
            }
            
            updatedQuestions.visible = true;
            // console.log(updatedQuestions.visible)
        },
        extractVideoId(url) {
            const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
            const matches = url.match(regex);
            return matches ? matches[1] : null;
        },
        checkAnswerCorrect(is_correct) {
            // Assuming this.is_correct is a data property in your component
                this.correctAnswer = is_correct === 1;
        },
        submitQuestion(question_id, selectedAnswer, index) {
            const formData = new FormData();
            formData.append('exam_id', this.exam.id);
            formData.append('question_id', question_id);
            if(selectedAnswer) {
                formData.append('answer_id', selectedAnswer);
            }


            if(selectedAnswer) {
              // Avoid mutating the prop directly, create a copy and update it
              const updatedQuestions = [...this.questions];
              updatedQuestions[index].submitQuestion = true;

              this.allQuestionsSubmitted = updatedQuestions.every(question => question.submitQuestion);
              // console.log(this.allQuestionsSubmitted)
              if (this.questions.length - 1 != this.activeTab) {
                // Can move between questions
                if (this.exam.can_move_between_questions == 0) {
                  // Create a copy of the questions array
                  const updatedQuestions = [...this.questions];
                  // Disable the current question tab
                  updatedQuestions[index].disabled = true;
                }

                axios
                    .post(`/submit_question_answer`, formData)
                    .then(({ data }) => {
                      this.loading = true;
                      this.answerData = data.data;


                      this.correctAnswer = false
                      // Move to the next tab
                      this.activeTab++;
                    }).catch((error) => {
                  // console.log(error.response.data.message);
                  Swal.fire({
                    text: error.response.data.message,
                    icon: "error",
                  });
                }).finally(() => (this.loading = false));
              }


              else if(this.allQuestionsSubmitted) {
                axios
                    .post(`/submit_question_answer`, formData)
                    .then(({ data }) => {
                      this.loading = true;
                      this.answerData = data.data;

                      if (this.exam.correction_status == 2) {
                        Swal.fire({
                          title: this.$t("success_ended_exam"),
                          html: `
                                    <p>${this.$t("correct_answer")}: ${this.answerData.correct_answers}</p>
                                    <p>${this.$t("wrong_answer")}: ${this.answerData.fail_answers}</p>
                                `,
                          icon: "success",
                        });
                        this.$router.push("/exams")
                      }
                      else if (this.exam.correction_status == 3 || this.exam.correction_status == 1) {
                        Swal.fire({
                          title: this.$t("success_ended_exam"),
                          text: this.$t("review_exam_and_return_result"),
                          icon: "success",
                        });
                        this.$router.push("/exams")
                      }
                      else {
                        Swal.fire({
                          title: this.$t("success_ended_exam"),
                          html: `
                                    <p>${this.$t("correct_answer")}: ${this.answerData.correct_answers}</p>
                                    <p>${this.$t("wrong_answer")}: ${this.answerData.fail_answers}</p>
                                `,
                          icon: "success",
                        });
                        this.$router.push("/exams")
                      }

                    }).catch((error) => {
                  // console.log(error.response.data.message);
                  Swal.fire({
                    text: error.response.data.message,
                    icon: "error",
                  });
                }).finally(() => (this.loading = false));
              }
              else {
                Swal.fire({
                  title: this.$t("not_answered_all_questions"),
                  text: this.$t("are_you_sure_end_exam"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: this.$t("back"),
                  confirmButtonText: this.$t("end_exam"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    axios
                        .post(`/submit_question_answer`, formData)
                        .then(({ data }) => {
                          this.loading = true;
                          this.answerData = data.data;

                          if (this.exam.correction_status === 2) {
                            Swal.fire({
                              title: this.$t("success_ended_exam"),
                              html: `
                                            <p>${this.$t("correct_answer")}: ${this.answerData.correct_answers}</p>
                                            <p>${this.$t("wrong_answer")}: ${this.answerData.fail_answers}</p>
                                        `,
                              icon: "success",
                            });
                            this.$router.push("/exams")
                          }
                          else if (this.exam.correction_status === 3 || this.exam.correction_status === 1) {
                            Swal.fire({
                              title: this.$t("success_ended_exam"),
                              text: this.$t("review_exam_and_return_result"),
                              icon: "success",
                            });
                            this.$router.push("/exams")
                          }
                          else {
                            Swal.fire({
                              title: this.$t("success_ended_exam"),
                              html: `
                                    <p>${this.$t("correct_answer")}: ${this.answerData.correct_answers}</p>
                                    <p>${this.$t("wrong_answer")}: ${this.answerData.fail_answers}</p>
                                `,
                              icon: "success",
                            });
                            this.$router.push("/exams")
                          }

                        }).catch((error) => {
                      // console.log(error.response.data.message);
                      Swal.fire({
                        text: error.response.data.message,
                        icon: "error",
                      });
                    }).finally(() => (this.loading = false));
                  }
                });
              }
            }
            else {
              Swal.fire({
                title: this.$t("please_select_answer"),
                icon: "warning",
              });
            }
        },
    }
}
</script>