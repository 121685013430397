<template>
  <div class="header_course">
    <div class="container">
      <div class="breadcrumb">
        <router-link to="/">{{ $t("home") }} / </router-link>
        <router-link to="/courses">{{ $t("courses") }} / </router-link>
        <span>{{ course.course.name }}</span>
      </div>
      <h2 class="title_course">{{ course.course.name }}</h2>
      <p class="description_course">
        {{ course?.course?.description?.substring(0, 100) + " ..." }}
      </p>
      <ul class="statistics">
        <li>
          <LessonIcon :color="PrimaryColor" />{{ course.course.lessons_number }}
          {{ $t("lessons") }}
        </li>
        <li>
          <VideoIcon :color="PrimaryColor" />{{ course.course.sessions_number }}
          {{ $t("sessions") }}
        </li>
        <li>
          <ExamIcon :color="PrimaryColor" />{{ course.course.exams_count }}
          {{ $t("exams") }}
        </li>
      </ul>
      <!-- course card price laptop -->
      <div class="course_card_price">
        <div class="image">
          <img
            :src="
              course.course.image
                ? course.course.image
                : require('@/assets/media/logo.png')
            "
            alt="course image"
          />
          <i class="fa-solid fa-circle-play play"></i>
        </div>
        <div class="price">
          <p class="title">{{ $t("course_price") }}</p>
          <p class="text">
            {{ course.course.price }} {{ course.course.currency }}
          </p>
        </div>
        <button
          type="button"
          class="btn BlueButton"
          v-if="!course.course.available"
          @click="subscribeCourse"
        >
          {{ $t("subscribe_now") }}
        </button>
        <Toast />
        <p
          class="payment_number"
          v-if="
            course?.course?.payment_number && course?.course?.available !== 1
          "
        >
          {{ $t("please_transfer_amount") }}
          <span class="phone_number" @click="copyText">{{
            course.course.payment_number
          }}</span>
        </p>
      </div>
    </div>
  </div>
  <!-- course card price mobile -->
  <div class="course_card_price_mobile">
    <div class="image">
      <img :src="course.course.image" alt="course image" />
      <i class="fa-solid fa-circle-play play"></i>
    </div>
    <div class="price">
      <p class="title">{{ $t("course_price") }}</p>
      <p class="text">{{ course.course.price }} {{ course.course.currency }}</p>
    </div>
    <button
      type="button"
      class="btn BlueButton"
      v-if="!course.course.available"
      @click="subscribeCourse"
    >
      {{ $t("subscribe_now") }}
    </button>
    <p class="payment_number" v-if="course?.course?.payment_number">
      {{ $t("please_transfer_amount") }}
      <span class="phone_number" @click="copyText">{{
        course.course.payment_number
      }}</span>
    </p>
  </div>
  <div class="course_details" :class="{ eventNone: !auth }">
    <div class="container">
      <div class="course_data">
        <h1 class="title">
          {{ $t("details") }}
          <span
            >{{ $t("course") }}
            <hr class="line"
          /></span>
        </h1>
        <p class="description_course">{{ course.course.description }}</p>
      </div>
      <Lessons v-if="course.lessons.length" :lessons="course.lessons" />
      <Exams v-if="course.exams.length" :exams="course.exams" />
      <Documents v-if="course.documents.length" :documents="course.documents" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Lessons from "@/components/include/course/lessons.vue";
import Exams from "@/components/include/course/exams.vue";
import Documents from "@/components/include/course/documents.vue";
import Toast from "primevue/toast";
import LessonIcon from "@/assets/icons/lessonIcon.vue";
import VideoIcon from "@/assets/icons/videoIcon.vue";
import ExamIcon from "@/assets/icons/examIcon.vue";

export default {
  name: "courses-page",
  data() {
    return {
      course: {
        course: {
          name: "",
          description: "",
          lessons_number: "",
          sessions_number: "",
          exams_count: "",
          payment_number: "",
        },
        lessons: [],
        exams: [],
        documents: [],
        auth: false,
      },
      auth: this.$store.getters["isAuthenticated"],
      loading: true,
      PrimaryColor: JSON.parse(localStorage.getItem("web_status"))
        ?.primary_color,
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
      textToCopy: "",
      copied: false,
    };
  },
  components: {
    Lessons,
    Exams,
    Documents,
    Toast,
    LessonIcon,
    VideoIcon,
    ExamIcon,
  },
  methods: {
    fetchCourseDetails() {
      this.loading = true;
      const formData = new FormData();
      if (this.auth) {
        if (this.user_id) {
          formData.append("user_id", this.user_id);
        }
      }
      formData.append("course_id", this.$route.params.id);
      axios
        .post(`/fetch_course_details`, formData)
        .then(({ data }) => {
          this.loading = false;
          this.course = data.data;
          //   console.log(this.course);
        })
        .catch((error) => {
          return error;
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
    },

    subscribeCourse() {
      const subscribe_data = {
        course_id: this.$route.params.id,
        // group_id: null,
        type: 0,
        join_type: 0, // 0 => online , 1 => center , 2 => online && center
        payment_method_id: 1,
      };
      if (this.auth) {
        axios
          .post(`/subscribe_course`, subscribe_data)
          .then(() => {
            this.loading = true;
            // this.course.course.available = 1;
            // this.course = data.data;
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.$t("send_subscribe_successfully"),
            });
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: error.response.data.message,
            });
            return error;
          })
          .finally(() => (this.loading = false));
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: this.$t("login_first"),
          confirmButtonText: this.$t("login_now"),
          customClass: {
            confirmButton: "BlueButton", // Add your custom class here
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/login");
          }
        });
      }
    },

    async copyText() {
      try {
        await navigator.clipboard.writeText(
          this.course?.course?.payment_number
        );
        this.copied = true;
        this.$toast.add({
          severity: "success",
          summary: this.$t("copy_success"),
          detail: this.course?.course?.payment_number,
          life: 3000,
        });
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
  },
  created() {
    this.fetchCourseDetails();
    this.auth = this.$store.getters["isAuthenticated"];
  },
};
</script>



<style scoped>
.course_details .lessons {
  margin-top: 7rem;
}
</style>
