<template>
    <!-- Modal -->
    <div class="modal modal_download_app fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="title">{{$t("download_application")}}</h4>
                    <div class="apps">
<!--                        <a v-if="data.windows" :href="data.windows" target="_blank"><img src="../../../assets/media/windows.png" alt="windows"></a>-->
<!--                        <a v-if="data.mac" :href="data.mac" target="_blank"><img src="../../../assets/media/mac.png" alt="mac"></a>-->
<!--                        <a v-if="data.huawei" :href="data.huawei" target="_blank"><img src="../../../assets/media/huawei.png" alt="huawei"></a>-->
                        <a v-if="data.ios" :href="data.ios" target="_blank"><img src="../../../assets/media/apple.png" alt="apple"></a>
                        <a v-if="data.android" :href="data.android" target="_blank"><img src="../../../assets/media/google.png" alt="google"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        data: Object,
    },
}
</script>

<style>

</style>