<template>
  <div class="lessons">
    <h5 class="title">{{ $t("lessons") }} ({{ lessons?.length }})</h5>
    <div
      class="accordion accordion-flush lessons_card"
      id="accordionFlushLesson"
    >
      <div
        class="accordion-item lesson_card"
        v-for="(lesson, index) in lessons"
        :key="index"
      >
        <h2 class="accordion-header" :id="`flush-lesson${lesson.id}`">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            @click="fetchLessonsDetails(lesson.id)"
            :data-bs-target="`#flush-collapseLesson${lesson.id}`"
            :aria-expanded="lesson.id in lessonContent ? 'true' : 'false'"
            :aria-controls="`flush-collapseLesson${lesson.id}`"
          >
            <div class="lesson_check" v-if="lesson.available">
              <div class="d-flex flex-column align-items-start">
                <p class="name">
                  <LessonIcon :color="PrimaryColor" />
                  {{ lesson.name }}
                </p>
                <span class="description" v-if="lesson.description">{{
                  lesson.description.substring(0, 150)
                }}</span>
              </div>
            </div>
            <div
              class="lesson_check disabled"
              v-tooltip.top="$t('not_available_lesson_for_you')"
              v-else
            >
              <div class="d-flex flex-column align-items-start">
                <p class="name">
                  <LessonIcon :color="PrimaryColor" />
                  {{ lesson.name }}
                </p>
                <span class="description">{{
                  lesson.description.substring(0, 150)
                }}</span>
              </div>
              <div class="lock">
                <LockIcon :color="PrimaryColor" />
              </div>
            </div>
          </button>
        </h2>
        <div
          :id="`flush-collapseLesson${lesson.id}`"
          class="accordion-collapse collapse"
          :aria-labelledby="`flush-lesson${lesson.id}`"
        >
          <div class="accordion-body">
            <div class="loader" v-if="loadingLessonId === lesson.id">
              <Skeleton height="3rem" class="mb-2" v-for="i in 4" :key="i" />
            </div>
            <sidebar
              v-else
              :sessions="lessonContent[lesson.id]?.sessions || []"
              :exams="lessonContent[lesson.id]?.exams || []"
              :documents="lessonContent[lesson.id]?.documents || []"
              :lessons="lessonContent[lesson.id]?.lessonsChild || []"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LessonIcon from "@/assets/icons/lessonIcon.vue";
import LockIcon from "@/assets/icons/lockIcon.vue";
import axios from "axios";
import sidebar from "@/components/include/lesson/sidebar.vue";
import Skeleton from "primevue/skeleton";

export default {
  name: "lessons-card",
  data() {
    return {
      PrimaryColor: JSON.parse(localStorage.getItem("web_status"))
        ?.primary_color,
      auth: this.$store.getters["isAuthenticated"],
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
      sessions: [],
      exams: [],
      documents: [],
      lessonsChild: [],
      loading: false,
      loadingLessonId: null, // Track which lesson is being loaded
      collapsedLessons: {}, // To track the collapsed state of lessons
      initialLessonsLoaded: false, // Flag to check if lessons from props are loaded
      localLessons: [], // Create a local copy of lessons
      hasLessons: [],
      lessonContent: {},
    };
  },
  props: {
    lessons: {
      type: Array,
      default: () => [
        {
          id: 0,
          name: "",
          description: "",
          available: false,
        },
      ],
    },
  },
  components: {
    LessonIcon,
    LockIcon,
    sidebar,
    Skeleton,
  },

  methods: {
    async fetchLessonsDetails(lessonID) {
      this.loadingLessonId = lessonID;

      try {
        const formData = new FormData();
        formData.append("lesson_id", lessonID);
        if (this.auth && this.user_id) formData.append("user_id", this.user_id);

        const { data } = await axios.post(
          `/fetch_lesson_sessions_and_exams`,
          formData
        );

        this.lessonContent = {
          ...this.lessonContent,
          [lessonID]: {
            sessions: data.data.sessions,
            exams: data.data.exams,
            documents: data.data.documents,
            lessonsChild: data.data.lessons,
          },
        };
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingLessonId = null;
      }
    },

    // toggleAccordion(lessonID) {
    //   // Toggle the collapsed state for the accordion
    //   this.collapsedLessons[lessonID] = !this.collapsedLessons[lessonID];
    // },
  },
};
</script>
