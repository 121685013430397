<template>
  <div class="row">
    <div
      class="col-md-6 col-12"
      :class="{
        'col-lg-6': routeName === 'myCoursesPage',
        'col-lg-4': routeName !== 'myCoursesPage',
      }"
      v-for="(course, index) in courses"
      :key="index"
    >
      <div class="course_card">
        <img
          :src="
            course.image ? course.image : require('@/assets/media/logo.png')
          "
          class="image"
          :alt="course.name"
        />
        <div class="content">
          <p class="subject">{{ course.subject }}</p>
          <p class="name">{{ course.name }}</p>
          <p class="description">
            {{
              course?.description
                ? course?.description?.substring(0, 100) + " ..."
                : ""
            }}
          </p>
          <ul class="lists">
            <li class="list">
              <i class="fa-solid fa-person-chalkboard"></i>
              {{ course.lesson_numbers }} {{ $t("lesson") }}
            </li>
            <li class="list">
              <i class="fa-solid fa-podcast"></i> {{ course.sessions_numbers }}
              {{ $t("sessions") }}
            </li>
          </ul>
          <div class="d-flex justify-content-between align-items-center">
            <p class="price">
              {{ course.price }} {{ course.currency ?? "EGP" }}
            </p>
            <router-link
              :to="`/course/${course.id}`"
              class="btn show_details"
              >{{ $t("show_details") }}</router-link
            >
            <!-- <button type="button" class="btn show_details" v-if="course.video" data-bs-toggle="modal" :data-bs-target="`#videoModal${course.id}`">{{$t("show_details")}}</button> -->
          </div>

          <!-- Modal -->
          <!-- <div class="modal fade" :id="`videoModal${course.id}`" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      <iframe :src="course.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courses"],
  data() {
    return {
      auth: this.$store.getters["isAuthenticated"],
      routeName: this.$route.name,
    };
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 70%;
}
.btn-close {
  margin-inline-start: auto;
  display: block;
  filter: brightness(0) invert(1);
}
iframe {
  display: block;
  width: 100%;
  height: 700px;
  margin-top: 1rem;
}
.modal-content {
  background-color: #00000061;
}
</style>
