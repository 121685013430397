<template>
  <div class="sidebar_full_screen">
    <div class="sidebar">
<!--      {{lessons}}-->
      <Lessons :lessons="lessons" v-if="lessons.length" />
      <Sessions :sessions="sessions" v-if="sessions.length" />
      <Exams :exams="exams" v-if="exams.length" />
      <Documents :documents="documents" v-if="documents.length" />
    </div>
  </div>
</template>

<script>
import Sessions from '@/components/include/lesson/sessions.vue';
import Exams from '@/components/include/course/exams.vue';
import Documents from '@/components/include/course/documents.vue';
import Lessons from '@/components/include/course/lessons.vue';

export default {
  name: "sidebar-component",
  components: {
    Sessions,
    Exams,
    Documents,
    Lessons
  },
  props: {
    exams: {
      type: Array,
      default: () => []
    },
    sessions: {
      type: Array,
      default: () => []
    },
    documents: {
      type: Array,
      default: () => []
    },
    lessons: {
      type: Array,
      default: () => [
        {
          id: 0,
          name: "",
          description: "",
          available: false
        }
      ]
    }
  },
}
</script>
