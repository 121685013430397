<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <underMaintenance v-if="data.web_status == 1" />
  <someChanges v-if="data.web_status == 2" />
  <section @contextmenu.prevent="handleContextMenu" v-else>
    <navbar :logo="data.logo" />
    <router-view></router-view>
    <DownloadModal :data="data" />
    <Footer :data="data" />

    <SpeedDial :model="items" direction="left" :style="{ bottom: 0 }" />
  </section>
</template>

<script>
import navbar from "./components/layouts/navbar.vue";
import Footer from "./components/layouts/footer.vue";
import DownloadModal from "./components/include/Modal/DownloadModal.vue";
import axios from "axios";
import Loading from "@/components/include/LottieFiles/loading.vue";
import underMaintenance from "./components/include/staticPages/underMaintenance.vue";
import someChanges from "./components/include/staticPages/someChanges.vue";
import SpeedDial from "primevue/speeddial";

// require("../src/assets/scss/style.min.css");

export default {
  name: "App",
  data() {
    return {
      data: {
        primary_color: "",
        secondary_color: "",
        web_status: null,
        face: "",
        insta: "",
        youtube: "",
        whatsapp: "",
        twitter: "",
        android: "",
        ios: "",
        huawei: "",
        mac: "",
        windows: "",
        loading: true,
      },
      contact_data: {
        phone: "",
        messenger: "",
        whats_app: "",
      },
      items: [],
    };
  },
  metaInfo: {
    title: "",
  },
  components: {
    navbar,
    Footer,
    DownloadModal,
    Loading,
    underMaintenance,
    someChanges,
    SpeedDial,
  },
  methods: {
    async fetchWebStatus() {
      try {
        const response = await axios.get("/fetch_web_status");
        this.data = response.data.data;
        this.contact_data = response.data.data.contact_data;
        this.setItems();
        this.PrimaryColor = response.data.data.primary_color; // Assuming the response structure contains the new color
        this.SecondColor = response.data.data.secondary_color; // Assuming the response structure contains the new color
        const titleEl = document.querySelector("head title");
        titleEl.textContent = response.data.data.website_name;
        // console.log(this.PrimaryColor)
        this.updatePrimaryColor(); // Call the method to update the color variable
        this.updateFavicon(response.data.data.logo); // Update favicon dynamically
        this.loading = true; //the loading begin
        localStorage.setItem("web_status", JSON.stringify(this.data));
      } catch (error) {
        console.error("Error fetching new color:", error);
      } finally {
        this.loading = false; //the loading end
      }
    },

    updateFavicon(faviconUrl) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = faviconUrl;
    },

    setItems() {
      this.items = [
        {
          label: "Contact Phone",
          icon: "pi pi-phone",
          command: () => {
            const telLink = document.createElement("a");
            telLink.href = `tel:${this.contact_data.phone}`;
            telLink.click();
          },
        },
        {
          label: "WhatsApp",
          icon: "pi pi-whatsapp",
          command: () => {
            window.open(
              `https://wa.me/+2${this.contact_data.whats_app}`,
              "_blank"
            );
          },
        },
        {
          label: "Messenger",
          icon: "pi pi-facebook",
          command: () => {
            window.open(this.contact_data.messenger, "_blank");
          },
        },
      ];
    },

    updatePrimaryColor() {
      const style = document.createElement("style");
      style.innerHTML = `:root { --primary-color: ${this.PrimaryColor}; --second-color: ${this.SecondColor} }`;
      document.head.appendChild(style);
    },
  },
  created() {
    this.fetchWebStatus();
    // localStorage.setItem("defaultImage", require('./assets/media/placeholder.png'));

    // Close inspect

    // document.addEventListener("contextmenu", (event) => event.preventDefault());
    // document.onkeydown = function (e) {
    //   if (event.keyCode == 123) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
    //     return false;
    //   }
    // }
  },
};
</script>

<script setup>
// const handleContextMenu = (e) => {
//   e.preventDefault();
// };

const handleContextMenu = (e) => {
  if (process.env.NODE_ENV === "production") {
    e.preventDefault();
  }
};
</script>

<style lang="scss">
:root {
  --primary-color: var(--primary-color);
  --second-color: var(--second-color);
}
</style>
