<template>
  <footer id="contact">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-3 col-md-6 col-12">
          <img :src="data.logo" class="logo" alt="logo" />
          <p class="text">{{ footer.title }}</p>
          <ul class="socialicon">
            <li>
              <a
                target="_blank"
                v-if="data.whatsapp"
                :href="`https://wa.me/${data.whatsapp}`"
                ><i class="fab fa-whatsapp icon"> </i
              ></a>
            </li>
            <li>
              <a target="_blank" :href="data.face" v-if="data.face"
                ><i class="fa-brands fa-facebook-f icon"></i
              ></a>
            </li>
            <li>
              <a target="_blank" :href="data.insta" v-if="data.insta"
                ><i class="fa-brands fa-instagram icon"></i
              ></a>
            </li>
            <li>
              <a target="_blank" :href="data.linkedin" v-if="data.linkedin"
                ><i class="fa-brands fa-linkedin-in icon"></i
              ></a>
            </li>
            <li>
              <a target="_blank" :href="data.twitter" v-if="data.twitter"
                ><i class="fa-brands fa-twitter icon"></i
              ></a>
            </li>
            <li>
              <a target="_blank" :href="data.youtube" v-if="data.youtube"
                ><i class="fa-brands fa-youtube icon"></i
              ></a>
            </li>
            <li>
              <a target="_blank" :href="data.telegram" v-if="data.telegram"
                ><i class="fa-brands fa-telegram icon"></i
              ></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <h4 class="title">{{ $t("quick_links") }}</h4>
          <ul class="quick_links">
            <li>
              <a href="/#about" class="nav-link underline-closing">{{
                $t("about_us")
              }}</a>
            </li>
            <li>
              <router-link to="/faq" class="nav-link underline-closing">{{
                $t("faq")
              }}</router-link>
            </li>
            <li>
              <router-link to="/courses" class="nav-link underline-closing">{{
                $t("courses")
              }}</router-link>
            </li>
            <li>
              <a href="/#contact" class="nav-link underline-closing">{{
                $t("contact_us")
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 col-12">
          <h4 class="title">{{ $t("Keep_contact") }}</h4>
          <p class="text">{{ $t("enter_email_recive_new_news") }}</p>
          <form @submit.prevent="submitForm()">
            <input
              type="email"
              name=""
              id=""
              v-model="Form.email"
              class="form-control"
              :placeholder="$t('email')"
            />
            <button type="submit" class="btn PrimaryButton">
              {{ $t("subscribe_now") }}
            </button>
          </form>
        </div>
      </div>
      <p class="footer-bottom">
        {{ $t("all_rights_reserved") }}
        <router-link to="/">Learno App</router-link> 2023
      </p>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "footer-layout",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          logo: "",
          title: "",
          whatsapp: "",
          face: "",
          insta: "",
          linkedin: "",
          twitter: "",
          youtube: "",
          telegram: "",
          website_name: "",
        };
      },
    },
  },
  data() {
    return {
      footer: {},
      Form: {
        email: "",
      },
    };
  },
  methods: {
    fetchFooter() {
      axios.get("/fetch_footer").then(({ data }) => {
        this.footer = data.data;
        // console.log(this.footer);
      });
    },
    async submitForm() {
      axios
        .post("/contact_email", this.Form)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchFooter();
  },
};
</script>
