<template>
  <section
    class="middle"
    v-if="middle?.image || middle?.title || middle?.sub_title"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <img :src="middle.image" :alt="middle.title" />
        </div>
        <div class="col-lg-6 col-12 d-flex flex-column justify-content-center">
          <h4 class="title">{{ middle.title }}</h4>
          <p class="text">{{ middle.sub_title }}</p>
          <button
            class="btn PrimaryButton"
            data-bs-toggle="modal"
            data-bs-target="#downloadModal"
          >
            {{ $t("get_app") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import middleService from "@/services/middleService";

export default {
  name: "middle-home",
  data() {
    return {
      middle: {},
    };
  },
  methods: {
    async fetchMiddle() {
      this.middle = await middleService.fetchMiddleData();
    },
  },
  created() {
    this.fetchMiddle();
  },
};
</script>

<style>
/* Add your styles here */
</style>
